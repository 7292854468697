import {addRequest} from "../state/actions"
import {connect} from "react-redux"
import {Grid, Panel, Row} from "react-bootstrap"
import React, { Component } from 'react'
import FormList from "./FormList"
import Selector from "../components/Selector"

class FormListPage extends Component {
    constructor (props) {
        super(props)

        this.state = {
            personId: null,
            lists: {}
        }

        this.getForms = this.getForms.bind(this);
    }

    getPeopleWithFormRole(people) {
        return people
            .filter(person => person.assignable)
            .filter(person => person.roles.filter(role => role.startsWith("form:")).length > 0)
    }

    getForms(personId) {
        const {
            addRequest,
            people,
        } = this.props

        this.setState({lists: {}})

        let peopleIds = [ personId ]
        if (personId === 'All') {
            peopleIds = this.getPeopleWithFormRole(people).map(person => person.id)
        }

        peopleIds.forEach(thisPersonId => {
            const formUrl = 'form?personId=' + thisPersonId

            addRequest(
                'getForms-' + thisPersonId,
                {
                    url: formUrl,
                    method: 'GET',
                },
                body => {
                    this.setState((state) => {
                        return {
                            lists: {
                                ...state.lists,
                                [thisPersonId]: body
                            }
                        }
                    })
                }
            )
        })

    }

    render() {
        const {
            people
        } = this.props

        const items = [{
            id: 'All',
            value: 'All'
        }].concat(this.getPeopleWithFormRole(people).map(person => ({
            id: person.id,
            value: person.name
        })))

        const list = Object.keys(this.state.lists).flatMap(id => this.state.lists[id]).sort((a, b) => (a.dueDate < b.dueDate) ? 1 : -1)
        let count = null

        if (this.state.personId) {
            let countText
            switch (list.length) {
                case 0:
                    countText = 'No results'
                    break;
                case 1:
                    countText = '1 result'
                    break;
                default:
                    countText = `${list.length} results`
            }

            count = <h4 className={'pull-right'}>{countText}</h4>
        }

        return <Panel>
            <Panel.Heading>
                <Selector
                    id="personId"
                    title="Person"
                    closeButton={false}
                    selectedId={this.state.personId}
                    valueUpdated={(key, value) => {
                        this.setState({personId: value})
                        this.getForms(value)
                    }}
                    items={items}
                />
                {count}
            </Panel.Heading>
            <Panel.Body>
                <Grid fluid style={{marginLeft: '10px'}}>
                    <Row>
                        <FormList
                            list={list}
                            callback={() => this.getForms(this.state.personId)}
                        />
                    </Row>
                </Grid>
            </Panel.Body>
        </Panel>
    }
}

const mapStateToProps = (state) => {
    return {
        people: state.people,
    }
}

const mapDispatchToProps = (dispatch) => {
    return({
        addRequest: (requestKey, options, callback) => {dispatch(addRequest(requestKey, options, callback))},
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(FormListPage)
